import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import Layout from '../layouts/Index'
import * as EmailValidator from 'email-validator'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import SEO from '../components/SEO'
import Style from '../styles/pages/contact.module.scss'

class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sending: false
    }
  }

  handleSendEmail = (e) => {
    e.preventDefault();

    const _self = this;
    const api_endpoint = this.props.pageContext.WAKATOON_CONTACT_ENDPOINT;
    const formData = {
      'email': e.target.elements.email.value.trim(),
      'phone': e.target.elements.phone.value.trim(),
      'fullname': e.target.elements.fullname.value.trim(),
      'subject': e.target.elements.subject.value.trim(),
      'message': e.target.elements.formMessage.value.trim()
    }

    let initErrors = [
      !EmailValidator.validate(formData.email) && this._t('Veuillez saisir une adresse E-mail valide.'),
      formData.fullname.length === 0 && this._t('Veuillez saisir votre nom complet.'),
      formData.subject.length === 0 && this._t(`Votre message n'a pas de sujet.`),
      formData.message.length === 0 && this._t('Veuillez saisir votre message.'),
    ],
      errors = initErrors.filter((error) => {
        if (error) {
          return [...error];
        }
        return false;
      }),
      validForm = errors.length === 0;

    if (!validForm) {
      errors.forEach((error) => {
        return NotificationManager.error(error, null, 5000)
      });
      return false;
    }
    this.setState({ sending: true });

    formData.lang = this.props.pageContext.locale;
    fetch(api_endpoint, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        "X-Api-Key": this.props.pageContext.WAKATOON_MAILER_PUBLIC_KEY
      }
    })
      .then((r) => { console.log(r.body); return r.json() })
      .then(json => {
        if (typeof json === 'object') {
          if (json.success) {
            NotificationManager.success(`${this._t(`Votre message a bien été envoyé`)}.`, '', 3000);
          } else {
            NotificationManager.error(this._t('Une erreur est survenue.'), 'Erreur', 3000);
          }
        } else {
          NotificationManager.error(this._t('Une erreur est survenue.'), 'Erreur', 3000);
        }
        _self.setState({ sending: false });
        document.getElementById('contact-form').reset();
      }
      );
  }

  _t = (str) => {
    const _t = this.props.data.staticText.edges[0].node.data.texts;
    return _t[_t.findIndex(item => item.key_text === str)].text.text;
  };

  render() {
    const {
      data: { contactPageTemplate, seo, staticText }
    } = this.props;

    const staticPrismicTexts = staticText.edges[0].node.data.texts;
    const _data = contactPageTemplate.edges;
    const data = _data.length > 0 ? _data[0].node.data : [];
    const _seoData = seo.edges;
    const seoData = _seoData.length > 0 ? _seoData[0].node.data : [];

    return (
      <Layout
        pageTitle={seoData.contact_page_title.text}
        context={this.props.pageContext}
        _t={staticPrismicTexts}
      >
        <SEO
          pageTitle={seoData.contact_page_title.text}
          pageDescription={seoData.contact_page_description.text}
          context={this.props.pageContext}
        />
        <div className={`${Style.container} page"`}>
          <section>
            <div className="page-head">
              <h2>Contact</h2>
            </div>
            <div className="container">
              <div className={`${Style.row} row`}>
                <div className="col-12">
                  <h4>{data.title_block_2 && data.title_block_2.text}</h4>
                  <form onSubmit={this.handleSendEmail} id="contact-form">
                    <div className="form-group">
                      <div className={`row`}>
                        <div className={`${Style.col6} col-12`}>
                          <input type="text" className="form-control" id="fullname" name="fullname" placeholder={this._t('Nom')} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <div className={`row`}>
                        <div className={`${Style.col6} col-6`}>
                          <input type="email" className="form-control" id="email" name="email" placeholder="E-mail" />
                        </div>

                        <div className={`${Style.col6} col-6`}>
                          <input type="text" className="form-control" id="phone" name="phone" placeholder={this._t('N° de téléphone')} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <input type="text" className="form-control" id="subject" name="subject" placeholder={this._t('Sujet')} />
                    </div>

                    <div className="form-group">
                      <textarea className="form-control" id="formMessage" name="formMessage" rows="3"></textarea>
                    </div>

                    <div className="form-group centered">
                      <button className="button btn btn_default">
                        {this.state.sending ? this._t('Envoi en cours') : this._t('Envoyer')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className={`${Style.row} row`}>
                <div className="col-12">
                  <h4>{data.title_block_1 && data.title_block_1.text}</h4>
                  <div className={`${Style.contacts} centered`}>
                    {data.e_mails && data.e_mails.map((item, index) => {
                      return (
                        <p key={`contact-${index}`}>
                          <span>
                            <strong>{item.label.text}</strong>
                            <br /><a href={`mailto:${item.address}`}>
                              {item.address}
                            </a>
                          </span>
                        </p>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className={`${Style.row} ${Style.address} row`}>
                <div className={`${Style.col6} ${Style.right} col-6 right`}>
                  <h6>Wakatoon</h6>
                  <p>
                    MAIF Start-up Club<br />
                    38 rue des Jeuneurs<br />
                    75002, Paris, France.
                  </p>
                </div>
                <div className={`${Style.col6} col-6`}>
                  <p>
                    {/* <strong>t.</strong> {data.phone_number && data.phone_number.text}<br /> */}
                    <strong>@.</strong> <a href={`mailto:${data.e_mail ? data.e_mail.text : 'bonjour@wakatoon.com'}`}>
                      {data.e_mail && data.e_mail.text}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <NotificationContainer />
        </div>
      </Layout>
    );
  };
};

const mapStateToProps = (state) => ({
  ...{ viewportSize: state.viewport.viewportSize }
});

export default connect(mapStateToProps)(Contact);

export const pageQuery = graphql`
  query contactPageTemplate($locale: String!) {
    contactPageTemplate: allPrismicCopyContact(filter: { lang: { eq: $locale} }) {
      edges {
        ...contactTemplate
      }
    }
    seo: allPrismicStaticpagesseo(filter: { lang: { eq: $locale} }) {
      edges {
        ...StaticPagesSeo
      }
    }
    staticText: allPrismicGlobalStatictexts(filter: { lang: { eq: $locale} }) {
      edges { ...StaticTexts }
    }
  }
`;